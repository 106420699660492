var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { rounded: "" } },
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("div", [_vm._v("Xóa shipment")]),
              _c("v-spacer"),
              _c(
                "div",
                {},
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { disabled: _vm.isLoading, text: "" },
                      on: { click: _vm.onCloseDialog },
                    },
                    [_vm._v("Đóng")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                        color: "error",
                      },
                      on: { click: _vm.onDeleteOrderShipment },
                    },
                    [_vm._v("Xóa")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-card-text", { staticClass: "pb-6 pt-2" }, [
        _vm._v(" Bạn có chắc chắn muốn xóa shipment này không? "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }