<template>
  <v-card rounded>
    <v-card-title>
      <v-row no-gutters>
        <div>Tạo shipment</div>
        <v-spacer />
        <div class="">
          <v-btn
            :disabled="isLoading"
            text
            class="mr-2"
            @click="onCloseDialog"
            >Đóng</v-btn
          >
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            color="primary"
            @click="onCreateShipment"
            >Tạo</v-btn
          >
        </div>
      </v-row>
    </v-card-title>

    <v-card-text class="py-6">
      <v-form>
        <v-text-field
          v-model="driverName"
          label="Nhập tên lái xe"
          outlined
        />
        <v-text-field
          v-model="driverPhone"
          label="Nhập số điện thoại"
          outlined
        />
        <v-text-field
          v-model="driverIdentificationNumber"
          label="Nhập CMND/Hộ chiếu"
          outlined
        />
        <v-text-field
          v-model="driverVehicle"
          label="Nhập phương tiện"
          outlined
        />
        <v-text-field
          v-model="containerName"
          label="Nhập container"
          outlined
        />
        <v-row no-gutters>
          <v-col cols="7">
            <v-dialog
              ref="modalDatepicker"
              v-model="modalDatepicker"
              :return-value.sync="pickUpDate"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="$filters.formatDate(pickUpDate)"
                  label="Chọn ngày lấy hàng"
                  append-icon="mdi-calendar"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="pickUpDate"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modalDatepicker = false"
                >
                  Hủy
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modalDatepicker.save(pickUpDate)"
                >
                  Chọn
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="5">
            <v-dialog
              ref="modalTimepicker"
              v-model="modalTimepicker"
              :return-value.sync="pickUpTime"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="pickUpTime"
                  class="pl-2"
                  label="Chọn giờ"
                  outlined
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="modalTimepicker"
                v-model="pickUpTime"
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modalTimepicker = false"
                >
                  Hủy
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modalTimepicker.save(pickUpTime)"
                >
                  Chọn
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <div
          v-for="item in driverVehicleRegistrationFiles"
          :key="item.id"
          class="d-flex align-center"
        >
          <a
            class="d-block"
            :class="{
              disabled: item.isTemp,
            }"
            :href="item.link"
            target="_blank"
          >
            {{ item.displayName }}
          </a>
          <v-btn
            class="mt-1"
            icon
            x-small
            @click="onRemoveFile(item)"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
        <FilePicker
          class="mt-2"
          @change="onSelectFile"
        />
      </v-form>
      <div class="mt-4">
        <v-btn
          :disabled="isLoading"
          :loading="isLoading"
          color="primary"
          width="100%"
          @click="onCreateShipment"
        >
          Lưu
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import PortPicker from '@/components/shared/local/vpl/PortPicker.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import CustomerServicePicker from '@/components/shared/local/vpl/CustomerServicePicker.vue';
import FilePicker from '@/components/shared/local/base/FilePicker.vue';
import dayjs from 'dayjs';

export default {
  components: { PortPicker, CustomerServicePicker, FilePicker },
  props: {},
  data: () => ({
    isSelecting: false,
    isLoading: false,
    driverName: '',
    driverPhone: '',
    driverVehicle: '',
    driverVehicleRegistrationFiles: [],
    driverIdentificationNumber: '',
    containerName: '',
    pickUpDate: dayjs().format('YYYY-MM-DD'),
    pickUpTime: dayjs().format('hh:mm'),
    modalDatepicker: false,
    modalTimepicker: false,
  }),
  computed: {
    ...mapState('vpl/upload', ['uploadedFileList']),
  },
  async created() {},
  watch: {},
  methods: {
    ...mapActions('vpl/order', [
      'createOrderShipment',
      'fetchOrderShipmentList',
    ]),
    ...mapActions('vpl/upload', ['uploadFile']),
    ...mapMutations('vpl/order', { RESET_ORDER: 'RESET' }),
    ...mapMutations('vpl/upload', { RESET_UPLOAD: 'RESET' }),
    onCloseDialog() {
      this.$emit('close-dialog');
    },
    async onCreateShipment() {
      try {
        this.isLoading = true;
        const formDataList = this.driverVehicleRegistrationFiles
          .filter(({ isTemp }) => !!isTemp)
          .map(({ formData }) => formData);
        await this.uploadFile({ formDataList });
        this.driverVehicleRegistrationFiles = this.uploadedFileList;
        const form = {
          orderId: this.$route.params.id,
          driverName: this.driverName,
          driverPhone: this.driverPhone,
          driverVehicle: this.driverVehicle,
          driverVehicleRegistrationFiles: this.driverVehicleRegistrationFiles,
          driverIdentificationNumber: this.driverIdentificationNumber,
          containerName: this.containerName,
          pickUpDate: this.pickUpDate,
          pickUpTime: this.pickUpTime,
        };
        await this.createOrderShipment(form);
        await this.fetchOrderShipmentList({
          orderId: this.$route.params.id,
        });
        this.RESET_UPLOAD();
        this.$emit('close-dialog');
        this.$message.success('Tạo shipment thành công');
      } finally {
        this.isLoading = false;
      }
    },
    onSelectFile(files) {
      this.driverVehicleRegistrationFiles.push(...files);
    },
    onRemoveFile(item) {
      this.driverVehicleRegistrationFiles =
        this.driverVehicleRegistrationFiles.filter(
          (attach) => attach.id != item.id
        );
    },
  },
};
</script>

<style></style>
