<template>
  <v-card
    style="overflow-y: auto"
    :ripple="false"
    width="100%"
    height="100%"
    max-height="550px"
    rounded="lg"
    class="pa-6"
  >
    <p class="text-left text-h6 font-weight-medium">
      <span class="d-flex align-center">
        <v-icon
          color="primary"
          left
          >mdi-history</v-icon
        >
        <span class="primary--text">LỊCH SỬ</span>
      </span>
    </p>
    <v-timeline
      align-top
      dense
    >
      <v-timeline-item
        v-for="(item, index) in orderActionLogList"
        :key="item.id"
        :color="index === 0 ? 'success' : 'grey'"
        small
      >
        <v-row class="">
          <v-col>
            <span
              style="font-size: 14px"
              v-html="formatActionType(item)"
              >{{
            }}</span>
            <div
              class="text-caption text--secondary"
              style="font-size: 12px"
            >
              Lúc {{ formatTime(item.datetime) }}
              {{ formatDate(item.datetime) }}
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';
import { OrderActionTypes } from '@/constants/status.constant';
export default {
  computed: {
    ...mapState('vpl/order', ['order', 'orderActionLogList']),
  },
  async created() {
    await this.fetchOrderActionLogList({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions('vpl/order', ['fetchOrderActionLogList']),
    formatDate(value) {
      return dayjs(value).format('DD.MM.YYYY');
    },
    formatTime(value) {
      return dayjs(value).format('HH:mm');
    },
    formatActionType(item) {
      if (item.type === OrderActionTypes.ORDER_DRAFTED) {
        return 'Khởi tạo order';
      }
      if (item.type === OrderActionTypes.ORDER_SUBMITTED) {
        return 'Đã gửi order';
      }
      if (item.type === OrderActionTypes.ORDER_APPROVED) {
        return 'Order đã được duyệt ';
      }
      if (item.type === OrderActionTypes.DRIVER_CHECKED_IN) {
        return `Lái xe <b>${item.driverName}</b> đã Check in`;
      }
      if (item.type === OrderActionTypes.DRIVER_ENTERED_WAREHOUSE) {
        return `Lái xe <b>${item.driverName}</b> đã vào kho`;
      }
      if (item.type === OrderActionTypes.DRIVER_CHECKED_OUT) {
        return `Lái xe <b>${item.driverName}</b> đã Check out`;
      }
      if (item.type === OrderActionTypes.DRIVER_SELF_CHECKED_IN) {
        return `Lái xe <b>${item.driverName}</b> đã Check in`;
      }
      if (item.type === OrderActionTypes.DRIVER_SELF_CHECKED_OUT) {
        return `Lái xe <b>${item.driverName}</b> đã Check out`;
      }
      if (item.type === OrderActionTypes.CUSTOMER_CREATED_SHIPMENT) {
        return `CUSTOMER_CREATED_SHIPMENT`;
      }
      if (item.type === OrderActionTypes.CUSTOMER_UPDATED_SHIPMENT) {
        return `CUSTOMER_UPDATED_SHIPMENT`;
      }
      if (item.type === OrderActionTypes.CUSTOMER_DELETED_SHIPMENT) {
        return `CUSTOMER_DELETED_SHIPMENT`;
      }
      if (item.type === OrderActionTypes.SYSTEM_CREATED_SHIPMENT) {
        return `Hệ thống đã tạo thêm shipment`;
      }
      if (item.type === OrderActionTypes.SYSTEM_UPDATED_SHIPMENT) {
        return `Hệ thống đã cập nhật một shipment`;
      }
      if (item.type === OrderActionTypes.SYSTEM_DELETED_SHIPMENT) {
        return `Hệ thống đã xóa một shipment`;
      }
      if (item.type === OrderActionTypes.CUSTOMER_REQUESTED_SHIPMENT) {
        return `Bạn đã gửi một shipment mới`;
      }
      if (item.type === OrderActionTypes.SYSTEM_APPROVED_SHIPMENT) {
        return `Shipment của bạn đã được duyệt`;
      }
      return item.type;
    },
  },
};
</script>

<style></style>
