var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "pa-6",
          attrs: {
            "min-height": "400",
            ripple: false,
            width: "100%",
            rounded: "lg",
          },
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("p", { staticClass: "text-left text-h6 font-weight-medium" }, [
                _c(
                  "span",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-icon", { attrs: { color: "primary", left: "" } }, [
                      _vm._v("mdi-graph-outline"),
                    ]),
                    _c("span", { staticClass: "primary--text" }, [
                      _vm._v("DANH SÁCH SHIPMENT"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.canUpdateOrder },
                  on: {
                    click: function ($event) {
                      _vm.dialog.orderShipmentCreate = true
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
                  _vm._v(" Tạo shipment "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {},
            [
              _c(
                "v-card",
                {
                  staticClass: "mt-6",
                  attrs: { elevation: "0", rounded: "xl" },
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { cols: "4" },
                            },
                            [_vm._v(" Thông tin lái xe ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold text-center",
                              attrs: { cols: "2" },
                            },
                            [_vm._v(" Ngày giờ lấy hàng ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold text-center",
                              attrs: { cols: "3" },
                            },
                            [_vm._v(" Trạng thái ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { cols: "2" },
                            },
                            [_vm._v(" Mã Check in/out ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold text-center",
                              attrs: { cols: "1" },
                            },
                            [_vm._v(" Thao tác ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.orderShipmentList, function (item) {
                return _c(
                  "v-card",
                  {
                    key: item.id,
                    staticClass: "mt-2",
                    attrs: {
                      color: "background",
                      elevation: "0",
                      rounded: "xl",
                      ripple: false,
                    },
                  },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "align-center",
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "table-cell",
                                attrs: { cols: "4" },
                              },
                              [
                                _c("ul", [
                                  _c("li", [
                                    _c("b", [_vm._v("Tên")]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(": " + _vm._s(item.driverName)),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("b", [_vm._v("Điện thoại")]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(": " + _vm._s(item.driverPhone)),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("b", [_vm._v("CMND")]),
                                    _c("span", [
                                      _vm._v(
                                        ": " +
                                          _vm._s(
                                            item.driverIdentificationNumber
                                          )
                                      ),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("b", [_vm._v("Phương tiện")]),
                                    _c("span", [
                                      _vm._v(": " + _vm._s(item.driverVehicle)),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("b", [_vm._v("Container")]),
                                    _c("span", [
                                      _vm._v(": " + _vm._s(item.containerName)),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("b", [_vm._v("Giấy tờ ")]),
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.onViewDriverVehicheRegistration(
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Xem")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$filters.formatDatetime(
                                        item.pickUpDate
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-center font-weight-medium",
                                attrs: { cols: "3" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatShipmentStatus(item.status)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("v-col", { attrs: { cols: "2" } }, [
                              _vm.isShowVerificationCode(item.status)
                                ? _c("b", { staticClass: "primary--text" }, [
                                    _vm._v(
                                      " " + _vm._s(item.verificationCode) + " "
                                    ),
                                  ])
                                : _c("span", { staticClass: "font-italic" }, [
                                    _vm._v("Chưa có"),
                                  ]),
                            ]),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "1" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      color: "amber darken-4",
                                      disabled: !_vm.canUpdateShipment(
                                        item.status
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onShowDialogShipmentUpdate(
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-circle-edit-outline"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      color: "error",
                                      disabled: !_vm.canUpdateShipment(
                                        item.status
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onShowDialogShipmentDelete(
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-close-thick")])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              !_vm.isLoading && _vm.orderShipmentList.length === 0
                ? _c("NoData")
                : _vm._e(),
              _vm.orderShipmentList.length > 0
                ? _c(
                    "p",
                    {
                      staticClass: "text-secondary font-italic mt-2 pa-2",
                      staticStyle: { "font-size": "12px" },
                    },
                    [
                      _vm._v(
                        " Mã Check in/out sẽ được cấp sau khi order được duyệt "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550px" },
          model: {
            value: _vm.dialog.orderShipmentCreate,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "orderShipmentCreate", $$v)
            },
            expression: "dialog.orderShipmentCreate",
          },
        },
        [
          _vm.dialog.orderShipmentCreate
            ? _c("OrderShipmentCreate", {
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.orderShipmentCreate = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550px" },
          model: {
            value: _vm.dialog.orderShipmentUpdate,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "orderShipmentUpdate", $$v)
            },
            expression: "dialog.orderShipmentUpdate",
          },
        },
        [
          _vm.dialog.orderShipmentUpdate
            ? _c("OrderShipmentUpdate", {
                attrs: { item: _vm.selectedShipment },
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.orderShipmentUpdate = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550px" },
          model: {
            value: _vm.dialog.orderShipmentDelete,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "orderShipmentDelete", $$v)
            },
            expression: "dialog.orderShipmentDelete",
          },
        },
        [
          _vm.dialog.orderShipmentDelete
            ? _c("OrderShipmentDelete", {
                attrs: { item: _vm.selectedShipment },
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.orderShipmentDelete = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "350px" },
          model: {
            value: _vm.dialog.viewDriverVehicheRegistration,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "viewDriverVehicheRegistration", $$v)
            },
            expression: "dialog.viewDriverVehicheRegistration",
          },
        },
        [
          _vm.dialog.viewDriverVehicheRegistration
            ? _c("OrderShipmentListFile", {
                attrs: { item: _vm.selectedShipment },
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.viewDriverVehicheRegistration = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }