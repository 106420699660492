var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { rounded: "" } },
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("div", [_vm._v("Chỉnh sửa shipment")]),
              _c("v-spacer"),
              _c(
                "div",
                {},
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { disabled: _vm.isLoading, text: "" },
                      on: { click: _vm.onCloseDialog },
                    },
                    [_vm._v("Đóng")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                        color: "primary",
                      },
                      on: { click: _vm.onUpdateShipment },
                    },
                    [_vm._v("Lưu")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "py-6" },
        [
          _c(
            "v-form",
            [
              _c("v-text-field", {
                attrs: { label: "Nhập tên lái xe", outlined: "" },
                model: {
                  value: _vm.driverName,
                  callback: function ($$v) {
                    _vm.driverName = $$v
                  },
                  expression: "driverName",
                },
              }),
              _c("v-text-field", {
                attrs: { label: "Nhập số điện thoại", outlined: "" },
                model: {
                  value: _vm.driverPhone,
                  callback: function ($$v) {
                    _vm.driverPhone = $$v
                  },
                  expression: "driverPhone",
                },
              }),
              _c("v-text-field", {
                attrs: { label: "Nhập CMND/Hộ chiếu", outlined: "" },
                model: {
                  value: _vm.driverIdentificationNumber,
                  callback: function ($$v) {
                    _vm.driverIdentificationNumber = $$v
                  },
                  expression: "driverIdentificationNumber",
                },
              }),
              _c("v-text-field", {
                attrs: { label: "Nhập phương tiện", outlined: "" },
                model: {
                  value: _vm.driverVehicle,
                  callback: function ($$v) {
                    _vm.driverVehicle = $$v
                  },
                  expression: "driverVehicle",
                },
              }),
              _c("v-text-field", {
                attrs: { label: "Nhập container", outlined: "" },
                model: {
                  value: _vm.containerName,
                  callback: function ($$v) {
                    _vm.containerName = $$v
                  },
                  expression: "containerName",
                },
              }),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-dialog",
                        {
                          ref: "modalDatepicker",
                          attrs: {
                            "return-value": _vm.pickUpDate,
                            width: "290px",
                          },
                          on: {
                            "update:returnValue": function ($event) {
                              _vm.pickUpDate = $event
                            },
                            "update:return-value": function ($event) {
                              _vm.pickUpDate = $event
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            value: _vm.$filters.formatDate(
                                              _vm.pickUpDate
                                            ),
                                            label: "Chọn ngày lấy hàng",
                                            "append-icon": "mdi-calendar",
                                            outlined: "",
                                            readonly: "",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.modalDatepicker,
                            callback: function ($$v) {
                              _vm.modalDatepicker = $$v
                            },
                            expression: "modalDatepicker",
                          },
                        },
                        [
                          _c(
                            "v-date-picker",
                            {
                              attrs: { scrollable: "" },
                              model: {
                                value: _vm.pickUpDate,
                                callback: function ($$v) {
                                  _vm.pickUpDate = $$v
                                },
                                expression: "pickUpDate",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.modalDatepicker = false
                                    },
                                  },
                                },
                                [_vm._v(" Hủy ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.modalDatepicker.save(
                                        _vm.pickUpDate
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Chọn ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c(
                        "v-dialog",
                        {
                          ref: "modalTimepicker",
                          attrs: {
                            "return-value": _vm.pickUpTime,
                            width: "290px",
                          },
                          on: {
                            "update:returnValue": function ($event) {
                              _vm.pickUpTime = $event
                            },
                            "update:return-value": function ($event) {
                              _vm.pickUpTime = $event
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "pl-2",
                                          attrs: {
                                            label: "Chọn giờ",
                                            outlined: "",
                                            "append-icon":
                                              "mdi-clock-time-four-outline",
                                            readonly: "",
                                          },
                                          model: {
                                            value: _vm.pickUpTime,
                                            callback: function ($$v) {
                                              _vm.pickUpTime = $$v
                                            },
                                            expression: "pickUpTime",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.modalTimepicker,
                            callback: function ($$v) {
                              _vm.modalTimepicker = $$v
                            },
                            expression: "modalTimepicker",
                          },
                        },
                        [
                          _vm.modalTimepicker
                            ? _c(
                                "v-time-picker",
                                {
                                  attrs: { "full-width": "" },
                                  model: {
                                    value: _vm.pickUpTime,
                                    callback: function ($$v) {
                                      _vm.pickUpTime = $$v
                                    },
                                    expression: "pickUpTime",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.modalTimepicker = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Hủy ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.modalTimepicker.save(
                                            _vm.pickUpTime
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Chọn ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.driverVehicleRegistrationFiles, function (item) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "d-flex align-center" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "d-block",
                        class: {
                          disabled: item.isTemp,
                        },
                        attrs: { href: item.link, target: "_blank" },
                      },
                      [_vm._v(" " + _vm._s(item.displayName) + " ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mt-1",
                        attrs: { icon: "", "x-small": "" },
                        on: {
                          click: function ($event) {
                            return _vm.onRemoveFile(item)
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v(" mdi-close ")])],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c("FilePicker", {
                staticClass: "mt-2",
                on: { change: _vm.onSelectFile },
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.isLoading,
                    loading: _vm.isLoading,
                    color: "primary",
                    width: "100%",
                  },
                  on: { click: _vm.onUpdateShipment },
                },
                [_vm._v(" Lưu ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }