var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { rounded: "" } },
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("div", [_vm._v("Giấy tờ lái xe")]),
              _c("v-spacer"),
              _c(
                "div",
                {},
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { text: "" },
                      on: { click: _vm.onCloseDialog },
                    },
                    [_vm._v("Đóng")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pb-6 pt-2" },
        [
          _vm.item.driverVehicleRegistrationFiles.length === 0
            ? _c("div", [_vm._v(" Không có ")])
            : _vm._l(_vm.item.driverVehicleRegistrationFiles, function (item) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "d-flex align-center" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "d-block",
                        class: {
                          disabled: item.isTemp,
                        },
                        attrs: { href: item.link, target: "_blank" },
                      },
                      [_vm._v(" " + _vm._s(item.displayName) + " ")]
                    ),
                  ]
                )
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }