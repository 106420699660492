<template>
  <v-card rounded>
    <v-card-title>
      <v-row no-gutters>
        <div>Giấy tờ lái xe</div>
        <v-spacer />
        <div class="">
          <v-btn
            text
            class="mr-2"
            @click="onCloseDialog"
            >Đóng</v-btn
          >
        </div>
      </v-row>
    </v-card-title>

    <v-card-text class="pb-6 pt-2">
      <div v-if="item.driverVehicleRegistrationFiles.length === 0">
        Không có
      </div>
      <div
        v-else
        v-for="item in item.driverVehicleRegistrationFiles"
        :key="item.id"
        class="d-flex align-center"
      >
        <a
          class="d-block"
          :class="{
            disabled: item.isTemp,
          }"
          :href="item.link"
          target="_blank"
        >
          {{ item.displayName }}
        </a>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({}),
  computed: {},
  async created() {},
  watch: {},
  methods: {
    onCloseDialog() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style></style>
