var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("p", { staticClass: "display-1 mt-2 mb-5 text-uppercase" }, [
            _vm._v("THÔNG TIN ORDER"),
          ]),
          _c("v-spacer"),
          _c(
            "v-col",
            { staticClass: "d-flex flex-column", attrs: { cols: "4" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: !_vm.canSendOrder || _vm.isLoading,
                    "x-large": "",
                    color: "primary",
                    width: "100%",
                  },
                  on: { click: _vm.onSubmitOrder },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-send")]),
                  _vm._v(" Gửi Order "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-4", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "pr-4", attrs: { cols: "8" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-6",
                  attrs: {
                    ripple: false,
                    width: "100%",
                    height: "100%",
                    rounded: "lg",
                  },
                },
                [
                  _c(
                    "p",
                    { staticClass: "text-left text-h6 font-weight-medium" },
                    [
                      _c(
                        "span",
                        { staticClass: "d-flex align-center" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "primary", left: "" } },
                            [_vm._v("mdi-information-outline")]
                          ),
                          _c("span", { staticClass: "primary--text" }, [
                            _vm._v("THÔNG TIN CHUNG"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("div", { staticClass: "mb-2" }, [
                          _c("div", { staticClass: "text-h6 text--primary" }, [
                            _vm._v("Trạng thái"),
                          ]),
                          _c("div", { staticClass: "text--primary" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatStatus(_vm.order.status)) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("div", { staticClass: "mb-2" }, [
                          _c("div", { staticClass: "text-h6 text--primary" }, [
                            _vm._v("Mã order"),
                          ]),
                          _c("div", { staticClass: "text--primary" }, [
                            _vm._v(" " + _vm._s(_vm.order.code) + " "),
                          ]),
                        ]),
                      ]),
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("div", { staticClass: "mb-2" }, [
                          _c("div", { staticClass: "text-h6 text--primary" }, [
                            _vm._v("Ngày tạo"),
                          ]),
                          _c("div", { staticClass: "text--primary" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatDate(_vm.order.createdAt)) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("div", { staticClass: "mb-2" }, [
                          _c("div", { staticClass: "text-h6 text--primary" }, [
                            _vm._v("Cảng"),
                          ]),
                          _c("div", { staticClass: "text--primary" }, [
                            _vm._v(" " + _vm._s(_vm.order.port.name) + " "),
                          ]),
                        ]),
                      ]),
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("div", { staticClass: "mb-2" }, [
                          _c("div", { staticClass: "text-h6 text--primary" }, [
                            _vm._v("Dịch vụ"),
                          ]),
                          _c("div", { staticClass: "text--primary" }, [
                            _vm._v(
                              " " + _vm._s(_vm.order.customerService.name) + " "
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mb-2" }, [
                    _c("div", { staticClass: "text-h6 text--primary" }, [
                      _vm._v("Giấy tờ"),
                    ]),
                    _vm.attachments.length === 0
                      ? _c(
                          "div",
                          { staticClass: "text--secondary font-italic" },
                          [_vm._v(" Chưa có giấy tờ ")]
                        )
                      : _c(
                          "div",
                          { staticClass: "text--primary" },
                          _vm._l(_vm.attachments, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "d-flex align-center",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "d-block",
                                    class: {
                                      disabled: item.isTemp,
                                    },
                                    attrs: {
                                      href: item.link,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.displayName) + " ")]
                                ),
                                _vm.canUpdateOrder
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "mt-1",
                                        attrs: { icon: "", "x-small": "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onRemoveAttachment(item)
                                          },
                                        },
                                      },
                                      [
                                        item.isTemp && _vm.isUpdatingAttachments
                                          ? _c("v-progress-circular", {
                                              attrs: {
                                                size: "14",
                                                indeterminate: "",
                                                width: "2",
                                              },
                                            })
                                          : _c("v-icon", [
                                              _vm._v(" mdi-close "),
                                            ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mb-6" },
                    [
                      _c("FilePicker", {
                        attrs: { disabled: !_vm.canUpdateOrder },
                        on: { change: _vm.onSelectFile },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _c("v-textarea", {
                        attrs: {
                          name: "user_note",
                          label: "Nhập nội dung ghi chú",
                          outlined: "",
                          "persistent-placeholder": "",
                          rows: "3",
                          "hide-details": "",
                          "auto-grow": "",
                          readonly: !_vm.canUpdateOrder,
                          loading: _vm.isUpdatingUserNote,
                        },
                        model: {
                          value: _vm.userNote,
                          callback: function ($$v) {
                            _vm.userNote = $$v
                          },
                          expression: "userNote",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "4" } }, [_c("OrderActionLogList")], 1),
        ],
        1
      ),
      _c("OrderShipmentList", { staticClass: "mb-4" }),
      _c("OrderCommentList"),
      _c("div", { staticStyle: { height: "150px" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }