<template>
  <div class="">
    <v-row no-gutters>
      <p class="display-1 mt-2 mb-5 text-uppercase">THÔNG TIN ORDER</p>
      <v-spacer />
      <v-col
        cols="4"
        class="d-flex flex-column"
      >
        <v-btn
          :loading="isLoading"
          :disabled="!canSendOrder || isLoading"
          x-large
          color="primary"
          width="100%"
          @click="onSubmitOrder"
        >
          <v-icon left>mdi-send</v-icon>
          Gửi Order
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      no-gutters
      class="mb-4"
    >
      <v-col
        cols="8"
        class="pr-4"
      >
        <v-card
          :ripple="false"
          width="100%"
          height="100%"
          rounded="lg"
          class="pa-6"
        >
          <p class="text-left text-h6 font-weight-medium">
            <span class="d-flex align-center">
              <v-icon
                color="primary"
                left
                >mdi-information-outline</v-icon
              >
              <span class="primary--text">THÔNG TIN CHUNG</span>
            </span>
          </p>

          <v-row no-gutters>
            <v-col cols="6">
              <div class="mb-2">
                <div class="text-h6 text--primary">Trạng thái</div>
                <div class="text--primary">
                  {{ formatStatus(order.status) }}
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="6">
              <div class="mb-2">
                <div class="text-h6 text--primary">Mã order</div>
                <div class="text--primary">
                  {{ order.code }}
                </div>
              </div>
            </v-col>

            <v-col cols="6">
              <div class="mb-2">
                <div class="text-h6 text--primary">Ngày tạo</div>
                <div class="text--primary">
                  {{ formatDate(order.createdAt) }}
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="6">
              <div class="mb-2">
                <div class="text-h6 text--primary">Cảng</div>
                <div class="text--primary">
                  {{ order.port.name }}
                </div>
              </div>
            </v-col>

            <v-col cols="6">
              <div class="mb-2">
                <div class="text-h6 text--primary">Dịch vụ</div>
                <div class="text--primary">
                  {{ order.customerService.name }}
                </div>
              </div>
            </v-col>
          </v-row>

          <div class="mb-2">
            <div class="text-h6 text--primary">Giấy tờ</div>
            <div
              v-if="attachments.length === 0"
              class="text--secondary font-italic"
            >
              Chưa có giấy tờ
            </div>
            <div
              v-else
              class="text--primary"
            >
              <div
                v-for="item in attachments"
                :key="item.id"
                class="d-flex align-center"
              >
                <a
                  class="d-block"
                  :class="{
                    disabled: item.isTemp,
                  }"
                  :href="item.link"
                  target="_blank"
                >
                  {{ item.displayName }}
                </a>
                <v-btn
                  v-if="canUpdateOrder"
                  class="mt-1"
                  icon
                  x-small
                  @click="onRemoveAttachment(item)"
                >
                  <v-progress-circular
                    v-if="item.isTemp && isUpdatingAttachments"
                    size="14"
                    indeterminate
                    width="2"
                  />
                  <v-icon v-else> mdi-close </v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <div class="mb-6">
            <FilePicker
              @change="onSelectFile"
              :disabled="!canUpdateOrder"
            />
          </div>
          <div class="mb-2">
            <v-textarea
              v-model="userNote"
              name="user_note"
              label="Nhập nội dung ghi chú"
              outlined
              persistent-placeholder
              rows="3"
              hide-details
              auto-grow
              :readonly="!canUpdateOrder"
              :loading="isUpdatingUserNote"
            ></v-textarea>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4">
        <OrderActionLogList />
      </v-col>
    </v-row>

    <OrderShipmentList class="mb-4" />
    <OrderCommentList />
    <div style="height: 150px"></div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapMutations, mapState } from 'vuex';
import OrderActionLogList from './OrderActionLogList.vue';
import OrderShipmentList from './OrderShipmentList.vue';
import FilePicker from '@/components/shared/local/base/FilePicker.vue';
import { OrderStatus } from '@/constants/status.constant';
import OrderCommentList from './OrderCommentList.vue';
import { debounce, pluck } from 'rxjs/operators';
import { timer } from 'rxjs';

export default {
  components: {
    OrderActionLogList,
    OrderShipmentList,
    FilePicker,
    OrderCommentList,
  },
  computed: {
    ...mapState('vpl/order', ['order']),
    ...mapState('vpl/upload', ['uploadedFileList']),
    canSendOrder() {
      if (this.order.status === OrderStatus.DRAFT) {
        return true;
      }
      return false;
    },
    canUpdateOrder() {
      if (
        this.order.status === OrderStatus.DRAFT ||
        this.order.status === OrderStatus.PENDING
      ) {
        return true;
      }
      return false;
    },
  },
  data: () => ({
    isLoading: false,
    isInfoUpdating: false,
    isUpdatingUserNote: false,
    isUpdatingAttachments: false,
    attachments: [],
    userNote: '',
  }),
  async created() {
    await this.fetchOrder({ id: this.$route.params.id });
    this.attachments = this.order.attachmentFiles;
    this.userNote = this.order.userNote;
    this.$watchAsObservable('userNote')
      .pipe(
        pluck('newValue'),
        debounce(() => {
          this.isUpdatingUserNote = true;
          return timer(500);
        })
      )
      .subscribe(async (value) => {
        try {
          await this.updateOrderUserNote({
            id: this.$route.params.id,
            userNote: value,
          });
        } finally {
          this.isUpdatingUserNote = false;
        }
      });
  },
  destroyed() {
    this.RESET_ORDER();
  },
  methods: {
    ...mapMutations('vpl/order', { RESET_ORDER: 'RESET' }),
    ...mapMutations('vpl/upload', { RESET_UPLOAD: 'RESET' }),
    ...mapActions('vpl/order', [
      'fetchOrder',
      'fetchOrderShipmentList',
      'submitOrder',
      'updateOrder',
      'updateOrderUserNote',
      'updateOrderAttachments',
    ]),
    ...mapActions('vpl/upload', ['uploadFile']),
    formatDate(value) {
      return dayjs(value).format('DD.MM.YYYY');
    },
    formatTime(value) {
      return dayjs(value).format('HH:mm');
    },
    formatStatus(value) {
      if (value === OrderStatus.DRAFT) {
        return 'Khởi tạo';
      }
      if (value === OrderStatus.PENDING) {
        return 'Chờ duyệt';
      }
      if (value === OrderStatus.APPROVED) {
        return 'Đã được duyệt';
      }
      if (value === OrderStatus.CANCELED) {
        return 'Đã hủy';
      }
      return value;
    },
    async onSelectFile(files) {
      try {
        this.isUpdatingAttachments = true;
        this.attachments.push(...files);
        const formDataList = this.attachments
          .filter(({ isTemp }) => !!isTemp)
          .map(({ formData }) => formData);
        await this.uploadFile({ formDataList });
        this.attachments = this.attachments.filter((item) => !item.isTemp);
        this.attachments.push(...this.uploadedFileList);
        await this.updateOrderAttachments({
          id: this.$route.params.id,
          attachmentFiles: this.attachments,
        });
        this.RESET_UPLOAD();
      } finally {
        this.isUpdatingAttachments = false;
      }
    },
    async onUpdateInfo() {
      try {
        this.isInfoUpdating = true;
        const formDataList = this.attachments
          .filter(({ isTemp }) => !!isTemp)
          .map(({ formData }) => formData);
        await this.uploadFile({ formDataList });
        this.attachments = this.attachments.filter((item) => !item.isTemp);
        this.attachments.push(...this.uploadedFileList);
        await this.updateOrder({
          id: this.$route.params.id,
          userNote: this.userNote,
          attachmentFiles: this.attachments,
        });
        await this.fetchOrder({ id: this.$route.params.id });
        this.RESET_UPLOAD();
        this.$message.success('Cập nhật thông tin order thành công');
      } finally {
        this.isInfoUpdating = false;
      }
    },
    async onRemoveAttachment(item) {
      this.attachments = this.attachments.filter(
        (attach) => attach.fileName != item.fileName
      );
      await this.updateOrderAttachments({
        id: this.$route.params.id,
        attachmentFiles: this.attachments,
      });
    },
    async onSubmitOrder() {
      try {
        this.isLoading = true;
        await this.submitOrder({ id: this.$route.params.id });
        this.$message.success('Gửi order thành công');
        this.$router.push('/app/port/my-order');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
